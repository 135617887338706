<template>
  <tr
    class="rcc-row"
    :class="{'rcc-row_attention': row.isAttention}"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <rcc-cell
      v-for="(headerItem, index) in headers"
      :key="index"
      :name="headerItem.value"
      :value="row[headerItem.value]"
      :modifiers="headerItem.colModifiers"
      @edit-action="$router.push({ path: headerItem.editPath + row.id })"
    >
      <template
        v-for="slotName in Object.keys($scopedSlots)"
        v-slot:[slotName]="slotScope"
      >
        <slot :name="slotName" v-bind="{ ...slotScope, row }" />
      </template>
    </rcc-cell>

    <slot name="row-end" />
  </tr>
</template>

<script>
import RccCell from './rcc-cell'

export default {
  name: 'RccRow',

  components: { RccCell },

  inheritAttrs: false,

  props: {
    row: {
      type: Object,
      default: () => ({})
    },

    headers: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang="scss" scoped>
.rcc-row {
  line-height: 1.3;
  background: #ffffff;
  height: 20px;
  min-height: 20px;

  &_attention {
    background: $data-table-row-attention-bg;
  }
}
</style>
